<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities"
              ></multiselect>
            </div>
            <div class="col-md-2">
              <label>Select City </label>
              <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getPrincipleAndCorporates()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Select Principle Branch </label>
              <multiselect
                v-model="principleBranchID"
                :options="principleBranches"
                :show-labels="false"
                label="principleBranchName"
                track-by="principleBranchID"
                @input="getRestaurantByPrincipleBranch"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="form-label">Select Corporate Branch </label>
              <multiselect
                v-model="corpBranchID"
                :options="corpBranches"
                :show-labels="false"
                label="corpBranchName"
                track-by="corpBranchID"
                @input="getRestaurantByCorporateBranch"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
          </div>
          <div class="row mt-2 mb-3">
            <div class="col-md-4">
              <label class="form-label" for="form row-endTime-input"
                >Select Restaurant Branch*</label
              >
              <multiselect
                v-model="restBranchID"
                :class="{
                  'is-invalid': submitted && $v.restBranchID.$error
                }"
                :options="restBranchArr"
                label="restaurantName"
                track-by="restBranchID"
                @input="
                  getFdbacktemplatebyrestbranch(restBranchID.restBranchID)
                "
              ></multiselect>
              <div
                v-if="submitted && $v.restBranchID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.restBranchID.required"
                  >This value is required.</span
                >
              </div>
            </div>
            <div class="col-md-2" style="width: auto; margin-top: auto">
              <button
                class="btn btn-themeOrange mt-4"
                v-on:click="applyFilter()"
              >
                Apply Filter
              </button>
            </div>
            <!-- Apply filter END-->

            <!-- Clear filter -->
            <div class="col-md-2" style="width: auto; margin-top: auto">
              <button
                class="btn btn-themeBrown mt-4"
                v-on:click="clearFilter()"
              >
                Clear Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div v-if="loading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
            content-class="p-3 text-muted"
            justified
            nav-class="nav-tabs-custom"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  >Feedback Reasons Group</span
                >
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-4 ">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                              v-model="perPage"
                              :options="pageOptions"
                              size="sm"
                              style="margin-left: 5px; margin-right: 5px"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :busy="isReportTableBusy"
                        :fields="fields"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :items="tableItems"
                        :per-page="50"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                        hover
                        outlined
                        responsive
                        striped
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(action)="data">
                          <router-link
                            v-if="editRight == 1"
                            :to="{
                              name: 'feedbackReasonGroup',
                              params: { id: data.item.id, type: 'edit' }
                            }"
                          >
                            <i
                              class="uil uil-edit-alt"
                              style="font-size: 19px;"
                              title="Edit"
                            ></i
                          ></router-link>
                          <!--                          <i-->
                          <!--                            v-if="deleteRight == 1"-->
                          <!--                            class="mdi mdi-delete"-->
                          <!--                            style="font-size: 19px;cursor: pointer;"-->
                          <!--                            title="Delete"-->
                          <!--                            @click="deleteTemplate(data.item.id)"-->
                          <!--                          ></i>-->
                        </template>
                        <template v-slot:cell(status)="data">
                          <span
                            v-if="data.item.status == 'Active'"
                            class="text-success"
                          >
                            {{ data.item.status }}
                          </span>
                          <span v-else class="text-danger">
                            {{ data.item.status }}
                          </span>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                              v-model="currentPage"
                              :per-page="tableTo"
                              :total-rows="tableTotal"
                              size="md"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config.json";

export default {
  name: "FeedbackReasonGroupTable",
  page: {
    title: "Feedback Reason Group List",
    meta: [
      {
        name: "Feedback Reason Group List",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Feedback Reason Groups List",
      items: [
        {
          text: "Feedback Reason Groups List",
          href: "/"
        }
      ],

      country: "",
      countryArr: [],
      city: "",
      cityArr: [],
      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      restBranchArr: [],
      restBranchID: "",
      loading: false,
      isReportTableBusy: false,
      tableData: [],
      tableTotal: "",
      tableTo: "",
      tableItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "action",
          sortable: true,
          tdClass: "align-center",
          label: "Action"
        },
        {
          key: "title",
          label: "Reason Group Name",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "created_at",
          label: "Created On",
          sortable: true,
          tdClass: "align-center"
        }
      ],
      editRight: "",
      deleteRight: ""
    };
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getAllCountry();
    this.checkUserRights();
  },
  computed: {
    rows() {
      return this.tableItems.length;
    }
  },

  methods: {
    checkUserRights() {
      this.path = this.$route.path;
      this.axios
        .post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path
        })
        .then(result => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
        });
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getAllCities() {
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
        });
    },
    getPrincipleAndCorporates() {
      this.getPrincipleBranches();
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/get-corporate-branches", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.corpBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantByPrincipleBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/principle/restaurants", {
          city: this.city ? this.city.city : "",
          principleBranchID: this.principleBranchID
            ? this.principleBranchID.principleBranchID
            : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantByCorporateBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/corporate/restaurants", {
          city: this.city ? this.city.city : "",
          corpBranchID: this.corpBranchID ? this.corpBranchID.corpBranchID : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    applyFilter() {
      if (this.principleBranchID.length <= 0 && this.corpBranchID.length <= 0) {
        alert("Please select either principle branch or corporate branch.");
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        alert(
          "You can create reason groups for either principle branch or corporate branch."
        );
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append(
          "corporate_branch_id",
          this.corpBranchID ? this.corpBranchID.corpBranchID : ""
        );
        formData.append(
          "principle_branch_id",
          this.principleBranchID ? this.principleBranchID.principleBranchID : ""
        );
        formData.append(
          "restaurant_branch_id",
          this.restBranchID ? this.restBranchID.restBranchID : ""
        );
        formData.append("loginTypeID", this.$storageData.login_type);

        this.axios
          .post(this.$loggedRole+"/feedback-template/get-reason-groups", formData)
          .then(result => {
            this.loading = false;
            this.tableData = result.data;
            this.tableItems = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            alert(error.response.data.message);
          });
      }
    },

    clearFilter() {},
    deleteTemplate(id) {
      if (id > 0) {
        if (confirm("Are you sure you want to delete this Template?")) {
          this.axios
            .post(this.$loggedRole+"/feedback-template/remove-reason-group", { id: id })
            .then(response => {
              alert(response.data.message);
              location.reload();
            })
            .catch(error => {
              alert(error.response.data.message);
            });
        }
      }
    }
  },
  middleware: "authentication"
};
</script>

<style scoped></style>
